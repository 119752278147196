
.buffer-order-item{
    padding-top: 15px;
}

.grid-header{
    font-weight: bold;
}

.grid-row-left{
    text-align: left;
}

.grid-row-center{
    text-align: center;
}

.grid-row-right{
    text-align: right;
}