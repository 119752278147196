.rs-picker-toolbar {
  display: flex;
  flex-flow: row nowrap;
}

.rs-picker-daterange-menu .rs-picker-daterange-panel-show-one-calendar .rs-picker-toolbar-ranges {
  width: auto;
  flex: 1 1 100%;
}

.rs-modal {
  margin-top: 45px;
}